import React from 'react';
import * as firebase from "firebase/app";
import { withStyles, } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import More from '@material-ui/icons/MoreVert';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { VERSION } from '../utils/constants';


const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: '2px',
  },
  title: {
    margin: '0 auto'
  },
  paper: {
    marginTop: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '2%',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '10%',
  },
  submit: {
    margin: '1%'
  },
}

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordError: false,
      emailError: false,
      loading: false,
      mainLoader: false,
    }
  }

  componentWillMount() {
    // this.setState({ mainLoader: true })
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.authorize(user.uid);
      } else {
        // No user is signed in.
        console.log("not logged in :(")
        this.setState({ mainLoader: false })
      }
    });

  }

  handleTextChange(field, event) {
    const { value } = event.target
    this.setState({ [field]: value, passwordError: false, emailError: false });
  }

  authorize(userId) {
    firebase.firestore().collection('profile').doc(userId).get().then(userProfileSnapshot => {
      const profile = userProfileSnapshot.data();
      if (profile.admin) {
        if (profile.roles.includes('administrator')) {
          this.props.history.replace('/admin/');
          return;
        }
        if (profile.roles.includes('supervisor')) {
          this.props.history.replace('/admin/workspace');
          return;
        }
        if (profile.roles.includes('driver')) {
          this.props.history.replace('/admin/deliveries');
          return;
        } else {
          this.props.history.replace('/admin/workspace');
          return;
        }
      } else {
        alert("No authorized access");
      }
      this.setState({ loading: false, mainLoader: false });
    })
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleCloseMenu() {
    this.setState({ anchorEl: null });
  }

  forgetPassword() {
    if (!this.state.email) {
      this.setState({ emailError: true });
      return;
    }
    this.setState({ mainLoader: true });

    firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
      this.setState({ forgetPasswordSuccess: true });
    }).catch(error => {
      this.setState({ loginError: error.message, });
    }).finally(() => this.setState({ mainLoader: false }));
  }

  loginHandler() {
    if (!this.state.email) {
      this.setState({ emailError: true });
      return;
    }
    if (!this.state.password) {
      this.setState({ passwordError: true });
      return;
    }
    this.setState({ loading: true });
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      firebase.auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(async (userCreds) => {
          console.log(userCreds);
          this.authorize(userCreds.user.uid);
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loginError: error.message, loading: false });
        });
    })
  }

  render() {
    const { classes } = this.props;
    const openMenu = Boolean(this.state.anchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="static" color='secondary'>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Grocery Store Zim
            </Typography>
            <IconButton edge="start" color="inherit" aria-label="Menu" onClick={this.handleMenu.bind(this)}>
              <More />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={this.handleCloseMenu.bind(this)}
            >
              <MenuItem>Version {VERSION}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <img style={{ height: '100px' }} src={require('../assets/img/icon.png')} />
              <center><label style={{ color: 'red' }}>{this.state.loginError}</label></center>
              <div className={classes.form}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={this.state.email}
                  onChange={this.handleTextChange.bind(this, 'email')}
                  error={this.state.emailError}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={this.state.password}
                  onChange={this.handleTextChange.bind(this, 'password')}
                  error={this.state.passwordError}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <div style={{ height: '100px' }}>
                  {
                    this.state.loading ?
                      <CircularProgress style={{ marginLeft: '48%' }} />
                      :
                      (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          className={classes.submit}
                          onClick={() => this.loginHandler()}
                        >
                          Sign In
                      </Button>
                      )
                  }
                </div>
                <Grid container>
                  <Grid item xs>
                    <Link color="secondary" variant="body2" onClick={this.forgetPassword.bind(this)}>
                      Forgot password?
                  </Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>

        </div>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.forgetPasswordSuccess}
          autoHideDuration={6000}
          onClose={() => this.setState({ forgetPasswordSuccess: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ forgetPasswordSuccess: false })}
            severity="success"
          >
            Reset password action was successful. Check your email for reset password email and follow instructions.
          </MuiAlert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.pickOrderFailure}
          autoHideDuration={6000}
          onClose={() => this.setState({ pickOrderFailure: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ pickOrderFailure: false })}
            severity="error"
          >
            An error occurred, check your network and try again.
          </MuiAlert>
        </Snackbar>

        <Backdrop open={this.state.mainLoader && !this.state.loading} style={{
          zIndex: 200,
          color: '#fff'
        }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

export default withStyles(styles)(Login)