import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';


const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export function DashboardItems(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <Typography component="p" variant="h4" style={{marginTop: '10%'}}>
        {props.value}
      </Typography>
      <Typography color="textSecondary" style={{marginTop: '10%'}} className={classes.depositContext}>
        {props.date}
      </Typography>
      <div>
      </div>
    </React.Fragment>
  );
}