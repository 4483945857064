import React from 'react';
import * as firebase from "firebase/app";
import 'firebase/firebase-functions';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import pink from '@material-ui/core/colors/pink';
import { DashboardItems } from '../Components/DashBoardTiles';
import Orders from '../Components/Orders';
import Chart from '../Components/Charts';
import clsx from 'clsx';
import {
  isMobileOnly
} from "react-device-detect";
import Title from '../Components/Title';
import { Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = {
  root: {
    flexGrow: 1,
    marginTop: '5%',
  },
  avatar: {
    margin: '0 auto',
    color: '#fff',
    backgroundColor: pink[500],
    width: 60,
    height: 60,
  },
  button: {
    margin: '0 auto',
    textDecoration: 'none',
  },
  paper: {
    padding: '5%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      orders: 0,
      transactions: 0,
      registrations: 0,
      totalServiceCharges: 0,
      averageRating: 0,
      ordersList: [],
      driverAssignedOrders: [],
      packagerAssignedOrders: [],
      profile: {},
      currentIncome: {},
      inProgressIncome: {},
      workDoneIncome: {},
      paidOrders: 0,
      customers: [],
      allOrders: [],
      totalValueOfStock: 0,
    }
  }

  componentWillMount() {
    const DB = firebase.firestore();
    const user = firebase.auth().currentUser;

    firebase.firestore().collection('profile').doc(user.uid).onSnapshot(profileSnapshot => {
      const profile = Object.assign({ uid: profileSnapshot.id }, profileSnapshot.data());
      this.setState({ profile: profile });
      if (!profile.roles.includes('administrator')) {
        const retrieveStats = firebase.functions().httpsCallable('retrieveStats');
        this.setState({ mainLoader: true });
        retrieveStats().then(result => {
          this.setState({ currentIncome: result.data.current, inProgressIncome: result.data.inProgress, workDoneIncome: result.data.workDone })
        }).catch(error => this.setState({ dashboardStatsError: true }))
          .finally(() => this.setState({ mainLoader: false }))
      }
    });


    this.unsubscribeOrdersRef = DB.collection('orders').onSnapshot(ordersSnapshot => {
      let ordersList = ordersSnapshot.docs.map(order => {
        return Object.assign(
          {
            id: order.id.substring(0, 10).toUpperCase(),
          },
          {
            created_at: order.data().created_at,
            date: new Date(order.data().created_at).toDateString(),
            status: order.data().status,
            name: order.data().customer.fullname,
            shipTo: `${order.data().recipient.address_1} ${order.data().recipient.address_2} ${order.data().recipient.town}`,
            paymentMethod: order.data().paymentCard ? `${order.data().paymentCard.paymentBrand} **** ${order.data().paymentCard.last4Digits}` : 'PayFast',
            amount: order.data().totalPriceBreakdown.total,
          }
        )
      });

      const paidOrders = ordersList.filter(x => ["Delivered", "Paid_Pending", "En_Route", "Awaiting_Packaging", "Awaiting_Dispatch", "Dispatched"].includes(x.status))

      ordersList.sort(this.compareTo)
      const allOrders = paidOrders;
      ordersList = ordersList.slice(0, 10);
      const totalServiceCharges = ordersSnapshot.docs.map(x => x.data()).reduce((sum, value) => sum + value.totalPriceBreakdown.serviceCharge * 1, 0).toFixed(2);

      this.setState({ orders: ordersSnapshot.size, ordersList, allOrders, totalServiceCharges, paidOrders: paidOrders.length });
    });


    this.unsubscribeTransactionsRef = DB.collection('transactions').where('status', '==', 'Succeeded').onSnapshot(transactionsSnapshot => {
      const transactions = transactionsSnapshot.docs.map(x => x.data());
      let totalTransactions = 0;
      for (let i = 0; i < transactions.length; i++) {
        const transaction = transactions[i]
        if (transaction.gateway == "payfast") {
          totalTransactions += transaction.payload.amount_gross * 1;
        } else {
          totalTransactions += transaction.amount * 1;
        }
      }
      //.reduce((sum, value) => sum + value.amount * 1, 0).toFixed(2);

      this.setState({ transactions: totalTransactions.toFixed(2) });
    });

    this.unsubscribeProductsRef = DB.collection('products').where('special_tracking_mode', '==', true).onSnapshot(productsSnapshot => {
      const products = productsSnapshot.docs.map(x => x.data());
      let totalValueOfStock = 0;
      for (let i = 0; i < products.length; i++) {
        const product = products[i];

        totalValueOfStock += product.units * product.sale_price;

      }

      this.setState({ totalValueOfStock });


    })


    this.unsubscribeTransactionsRef = DB.collection('reviews').onSnapshot(reviewsSnapshot => {
      const totalReviewSum = reviewsSnapshot.docs.map(x => x.data()).reduce((sum, review) => sum + review.ratingValue * 1, 0);

      const averageRating = (totalReviewSum / reviewsSnapshot.size).toFixed(2);
      this.setState({ averageRating, totalRatings: reviewsSnapshot.size });
    });

    this.unsubscribeProfilesRef = DB.collection('profile').where('admin', '==', false).onSnapshot(profileSnapshots => {
      this.setState({ registrations: profileSnapshots.size, customers: profileSnapshots.docs.map(x => x.data()) });
    });
  }


  displayDashboard() {
    const { classes } = this.props;
    const { currentIncome, inProgressIncome, workDoneIncome } = this.state;
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    if (!this.state.profile.roles) {
      return;
    }

    //console.log(this.state.profile)
    if (this.state.profile.roles.includes('administrator')) {
      return (
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardItems title="Total Orders Received" value={`${this.state.paidOrders} / ${this.state.orders}`} date={new Date().toDateString()} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardItems title="Registrations" value={this.state.registrations} date={new Date().toDateString()} />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardItems title="Total Revenue" value={`ZAR ${this.state.transactions}`} date={new Date().toDateString()} />
              </Paper>
            </Grid>
            {
              <Grid item xs={12} md={3} lg={3}>
                <Paper className={fixedHeightPaper}>
                  <DashboardItems title="Service Ratings" value={`${this.state.averageRating} / 5   (${this.state.totalRatings})`} date={new Date().toDateString()} />
                </Paper>
              </Grid>
            }

            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <DashboardItems title="Total Stock Value" value={`USD ${this.state.totalValueOfStock.toFixed(2)}`} date={new Date().toDateString()} />
              </Paper>
            </Grid>

            {
              !isMobileOnly &&
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Chart data={this.state.customers} label={"count"} title="Customer Registrations" />
                </Paper>
              </Grid>
            }

            {
              !isMobileOnly &&
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Chart data={this.state.allOrders} label={"count"} title="Orders Made" />
                </Paper>
              </Grid>
            }

            {/* Recent Orders */}
            {
              !isMobileOnly &&
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Orders orders={this.state.ordersList} />
                </Paper>
              </Grid>
            }

          </Grid>
          <Box pt={4}>
          </Box>
        </Container>
      );
    } else if (this.state.profile.roles.includes('driver') || this.state.profile.roles.includes('packager') || this.state.profile.roles.includes('supervisor')) {
      return (
        <Container maxWidth="md" style={{ marginTop: '2%' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <React.Fragment>
                  <Title>Current Income</Title>
                  <Typography component="p" variant="h4" style={{ marginTop: '1%' }}>
                    ${currentIncome.income}
                  </Typography>
                  <Grid container spacing={3} style={{ marginTop: '2%' }}>
                    <Grid item xs={6}>
                      <Typography component="p" variant="body1" style={{ marginTop: '1%' }}>
                        Delivered: {currentIncome.delivered}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="p" variant="body1" style={{ marginTop: '1%' }}>
                        Packaged: {currentIncome.packed}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography color="textSecondary" style={{ marginTop: '10%' }} className={classes.depositContext}>
                    {currentIncome.date}
                  </Typography>
                  <div>
                  </div>
                </React.Fragment>
              </Paper>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <React.Fragment>
                  <Title>Work in Progress</Title>
                  <Typography component="p" variant="h4" style={{ marginTop: '1%' }}>
                    ${inProgressIncome.pendingIncome}
                  </Typography>
                  <Grid container spacing={3} style={{ marginTop: '2%' }}>
                    <Grid item xs={6}>
                      <Typography component="p" variant="body1" style={{ marginTop: '1%' }}>
                        Deliveries: {inProgressIncome.delivery}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="p" variant="body1" style={{ marginTop: '1%' }}>
                        Packagings: {inProgressIncome.packing}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography color="textSecondary" style={{ marginTop: '10%' }} className={classes.depositContext}>
                    {inProgressIncome.date}
                  </Typography>
                  <div>
                  </div>
                </React.Fragment>
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <React.Fragment>
                  <Title>Paid To Date</Title>
                  <Typography component="p" variant="h4" style={{ marginTop: '1%' }}>
                    ${workDoneIncome.totalEarnedIncome}
                  </Typography>
                  <Grid container spacing={3} style={{ marginTop: '2%' }}>
                    <Grid item xs={6}>
                      <Typography component="p" variant="body1" style={{ marginTop: '1%' }}>
                        Deliveries: {workDoneIncome.delivered}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="p" variant="body1" style={{ marginTop: '1%' }}>
                        Packaged: {workDoneIncome.packedOrders}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography color="textSecondary" style={{ marginTop: '10%' }} className={classes.depositContext}>
                    {workDoneIncome.date}
                  </Typography>
                  <div>
                  </div>
                </React.Fragment>
              </Paper>
            </Grid>


          </Grid>
        </Container>
      );

    }

  }

  componentWillUnmount() {
    this.unsubscribeTransactionsRef();
    this.unsubscribeOrdersRef();
    this.unsubscribeProfilesRef();
  }

  compareTo(A, B) {
    if (A.created_at > B.created_at) {
      return -1;
    } else if (A.created_at > B.created_at) {
      return 0;
    } else {
      return 1
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.displayDashboard()}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.dashboardStatsError}
          autoHideDuration={6000}
          onClose={() => this.setState({ dashboardStatsError: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ dashboardStatsError: false })}
            severity="error"
          >
            An error occurred, check your network and try again.
          </MuiAlert>
        </Snackbar>

        <Backdrop open={this.state.mainLoader} style={{
          zIndex: 200,
          color: '#fff'
        }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  }
}

export default withStyles(styles)(Dashboard);
