import React from 'react';
import * as firebase from "firebase/app";
import { Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

import Slide from '@material-ui/core/Slide';


export default class Reviews extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      open: false,
      selectedEvent: {},
      errorMessage: null,
    }
  }

  componentWillMount() {
    firebase.firestore().collection('reviews').orderBy('created_at', 'desc').onSnapshot(reviewsSnapshots => {
      const data = reviewsSnapshots.docs.map(trxn => {
        const rowData = trxn.data();
        return Object.assign(rowData,
          {
            uid: trxn.id.substring(0, 10).toUpperCase(),
            created_at: new Date(rowData.created_at).toDateString(),
            externalTransactionRef: rowData.externalTransactionRef,
          }
        );
      });
      this.setState({ data, loader: false });
    })
  }


  render() {
    const DB = firebase.firestore();

    return (
      <div>

        <Paper style={{ margin: '2%' }}>
          <br/>
          <h2 style={{paddingLeft: '3%'}}>Customer Reviews</h2>
          <Table >
            <TableHead>
              <TableRow>
                <TableCell>Created At</TableCell>
                <TableCell align="left">Fullname</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Comment</TableCell>
                <TableCell align="left">Rating Description</TableCell>
                <TableCell align="left">Rating Value(/5)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {this.state.data.map(row => (
                <TableRow key={row.uid}>
                  <TableCell align="left">{new Date(row.created_at).toString().substring(0, 21)}</TableCell>
                  <TableCell align="left">{row.fullname}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="left">{row.comment}</TableCell>
                  <TableCell align="left">{row.ratingDescription}</TableCell>
                  <TableCell align="left">{row.ratingValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
