import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as firebase from "firebase/app";
import  "firebase/firebase-messaging";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import More from '@material-ui/icons/MoreVert';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockOpen from '@material-ui/icons/LockOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { DashboardsRoutes } from '../Routes';
import { VERSION } from '../utils/constants';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  icon: {
    margin: theme.spacing(0),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: '4%'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const askForPermissioToReceiveNotifications = async (profile) => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    firebase.firestore().collection('profile').doc(profile.uid).update({ webPushToken: token});

    if(profile.roles.includes('driver')) {
      const subScribeToWebNotificationTopic = firebase.functions().httpsCallable('subScribeToWebNotificationTopic');
      subScribeToWebNotificationTopic({topic: 'drivers', profile,  token }).then(() => {
        console.log("successfully subscribed");
      }).catch(error => console.log(error));

    }
  } catch (error) {
    console.error(error);
  }
}

function PersistentDrawerLeft(props) {
  const classes = useStyles();
  
  React.useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );
    
    firebase.firestore().collection('profile').doc(firebase.auth().currentUser.uid).onSnapshot(profileSnapshots => {
      const profile = Object.assign({ uid: profileSnapshots.id }, profileSnapshots.data());
      askForPermissioToReceiveNotifications(profile);
      setProfile(profile);
    });

  }, []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [currentTab, setCurrentTab] = React.useState(window.location.pathname);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleLogout() {
    firebase.auth().signOut().finally(() => {
      props.history.replace('/login', null)
    });
  }

  function isPermitted(userRoles, permittedRoles) {
    userRoles = userRoles || []
    let intersection = userRoles.filter(x => permittedRoles.includes(x));
    if (intersection.length > 0) {
      return true;
    }
    return false
  }

  function handleSetCurrentTab(path) {
    setCurrentTab(path);
  }


  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}

          color="secondary"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{ margin: '0 auto' }} variant="h6" noWrap>
              Grocery Store Admin
            </Typography>
            <IconButton edge="start" color="inherit" aria-label="Menu" onClick={handleMenu}>
              <More />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem>{firebase.auth().currentUser? firebase.auth().currentUser.displayName : ''}</MenuItem>
              <MenuItem>Version {VERSION}</MenuItem>
              <MenuItem onClick={handleLogout}> Logout </MenuItem>

            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {
              DashboardsRoutes.map((route, index) =>
                isPermitted(profile.roles, route.rolesWhitelisted) &&
                <ListItem selected={route.path === currentTab} key={index} onClick={()=> handleSetCurrentTab(route.path)} component={Link} button to={route.path}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <ListItemText primary={route.title} />
                </ListItem>
              )
            }
          </List>
          <Divider />
          <List>
            <ListItem button onClick={() => handleLogout()}>
              <ListItemIcon><LockOpen /></ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {
            DashboardsRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))
          }
        </main>
      </div>
    </Router>
  );
}

export default PersistentDrawerLeft;
