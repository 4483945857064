import React from 'react';
import PropTypes from 'prop-types';
import * as firebase from "firebase/app";
import 'firebase/firebase-functions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import CallIcon from '@material-ui/icons/Call';
import Nav from '@material-ui/icons/Navigation';



import {
  isMobileOnly
} from "react-device-detect";


function chunkify(a, n) {
  if (a.length === 0) {
    return [[], []]
  }

  if (a.length === 1) {
    return [a, []]
  }
  if (n < 2)
    return [a];

  var len = a.length,
    out = [],
    i = 0,
    size;

  if (len % n === 0) {
    size = Math.floor(len / n);
    while (i < len) {
      out.push(a.slice(i, i += size));
    }
  }

  while (i < len) {
    size = Math.ceil((len - i) / n--);
    out.push(a.slice(i, i += size));
  }

  return out;
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const styles = {
  root: {
    backgroundColor: '#fff',
    width: '100vw',
    marginTop: '2%',
    flexGrow: 1,
  },
  tablePaper: {
    width: '100vw',
    marginTop: '0%',
  },
  tabs: {
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
  },
  typography: {
    marginTop: '2%'
  }
};

class DeliveryWorkspace extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      orders: [],
      open: false,
      selectedOrderItems: [],
      selectedOrder: {},
      profile: {},
      openDeliveryConfirm: false,
      code: '',
      codeError: false,
      mainLoader: true,
      dispatchedOrders: [],
      enrouteOrders: [],
      deliveredOrders: [],
    }
  }

  componentWillMount() {
    const currentUser = firebase.auth().currentUser;


    firebase.firestore().collection('profile').doc(currentUser.uid).get().then(profileSnapshot => {
      const profile = profileSnapshot.data();
      firebase.firestore().collection('orders').orderBy('created_at').onSnapshot(orderSnapshots => {
        const orders = orderSnapshots.docs.map(order => {
          const rowData = order.data();
          return Object.assign({ uid: order.id }, rowData);
        });
        const dispatchedOrders = orders.filter(x => x.status === 'Dispatched');
        const enrouteOrders = orders.filter(x => x.status === 'En_Route' && x.driver_uid === currentUser.uid);

        let deliveredOrders = [];
        if (profile.roles) {
          deliveredOrders = profile.roles.includes("administrator") ? orders.filter(x => x.status === 'Delivered').reverse()
            :
            orders.filter(x => x.status === 'Delivered' && x.driver_uid === currentUser.uid).reverse();
        }

        this.setState({ orders, loader: false, mainLoader: false, dispatchedOrders, enrouteOrders, deliveredOrders });

      });
      this.setState({ profile })
    });
  }

  handleDeliveryConfirmClose = () => {
    this.setState({ openDeliveryConfirm: false, selectedOrder: {}, code: '', codeError: false });
  }

  handleDeliveryConfirmOpen = (order) => {
    console.log("order id => ", order.uid)
    this.setState({ openDeliveryConfirm: true, selectedOrder: order })
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  pickOrder(order) {
    const profile = this.state.profile;

    const pickOrderForDelivery = firebase.functions().httpsCallable('pickOrderForDelivery');
    this.setState({ mainLoader: true })
    pickOrderForDelivery({ order, profile }).then(value => {
      this.setState({ pickOrderSuccess: true, mainLoader: false });
    }).catch(reason => {
      this.setState({ pickOrderFailure: true, mainLoader: false });
      console.log(reason);
    });
  }

  deliverOrder(order) {
    const orderId = order.uid
    const currentUser = firebase.auth().currentUser;
    const profile = this.state.profile;
    const expoPushToken = order.customer.expoPushNotificationToken;

    if (this.state.code.toUpperCase() !== orderId.substring(10).toUpperCase()) {
      console.log("code =>", this.state.code);
      console.log("orderId.substring(10) =>", orderId.substring(10))
      this.setState({ codeError: true })
      return;
    }

    if (expoPushToken) {
      firebase.firestore().collection('notification').add({
        expoPushNotificationToken: expoPushToken,
        sound: 'default',
        title: 'Order Delivered',
        message: `Great news! Order #${orderId.substring(0, 10).toUpperCase()} has been delivered successfully. Thanks for doing business with us.`
      });
    }

    firebase.firestore().collection('orders').doc(orderId)
      .update({
        status: 'Delivered',
        deliveredAt: new Date().getTime(),
        driver: {
          fullname: profile.fullname,
          uid: currentUser.uid,
          email: profile.email,
          phone: profile.phone,
        },
        driver_uid: currentUser.uid,
      }).then(() => {
        this.handleDeliveryConfirmClose();
        this.setState({ successDelivery: true })
      });
  }

  successDeliveryClose = () => {
    this.setState({ successDelivery: false })
  }

  handleTextChange = (event) => {
    this.setState({ code: event.target.value, codeError: false })
  }

  handleClose() {
    this.setState({
      open: false, view: false,
      selectedOrderItems: [],
      selectedOrder: {}
    })
  }

  handleViewOrder(order) {
    this.setState({ open: true, view: true, selectedOrderItems: order.items, selectedOrder: order })

  }

  nothingFound(empty) {
    if (empty && isMobileOnly && !this.state.mainLoader) return <center><img style={{ marginTop: '5%' }} src={require('../assets/img/notfound.png')}></img></center>
    else return <div />
  }

  render() {
    const { classes } = this.props
    const { value, orders, selectedOrderItems, deliveredOrders, dispatchedOrders, enrouteOrders } = this.state;
    const orderItems = chunkify(selectedOrderItems, 2);
    const currentUser = firebase.auth().currentUser;

    return (
      <React.Fragment>
        <br />
        <br />

        {
          isMobileOnly ?
            <Tabs
              centered
              disableTouchRipple
              focusRipple={false}
              disableRipple
              variant="fullWidth"
              value={value}
              onChange={this.handleChange}
            >
              <Tab disableFocusRipple disableTouchRipple disableRipple label="Dispatched" {...a11yProps(0)} />
              <Tab disableFocusRipple disableTouchRipple disableRipple label="En Route" {...a11yProps(1)} />
              <Tab disableFocusRipple disableTouchRipple disableRipple label="Delivered" {...a11yProps(2)} />
            </Tabs>
            :
            <Tabs
              centered
              variant="fullWidth"
              scrollButtons="on"
              value={value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label="Dispatched" {...a11yProps(0)} />
              <Tab label="En Route" {...a11yProps(1)} />
              <Tab label="Delivered" {...a11yProps(2)} />
            </Tabs>
        }


        <TabPanel value={value} index={0}>
          {this.nothingFound(orders.filter(x => x.status === 'Dispatched').length === 0)}

          {
            isMobileOnly ?
              <Grid container spacing={3}>
                {
                  dispatchedOrders.map(row => (
                    <Grid item md={12} sm={6} style={{ width: '100%' }} >
                      <Card key={row.uid} style={{ marginTop: '1%' }}>
                        <CardContent>
                          <Typography className={classes.typography} gutterBottom variant="h5" component="h2">
                            #{row.uid.toUpperCase().substring(0, 10)}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Created At:</strong> {new Date(row.created_at).toDateString()}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Customer:</strong> {row.customer.fullname}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Cash back 💴:</strong> ${row.cashSend ? row.cashSend.amountToBeSent : 0}USD
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Recipient:</strong> {row.recipient.full_name}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Ship To:</strong> {`${row.recipient.address_1} ${row.recipient.address_2} ${row.recipient.town}`}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" size="small" color="secondary" onClick={() => this.pickOrder(row)}>
                            Pick Order
                          </Button>
                          <Button variant={'outlined'} size="small" color="primary" onClick={() => this.handleViewOrder(row)}>View Order</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid> :

              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Created At</TableCell>
                    <TableCell align="left">Ship To</TableCell>
                    <TableCell align="left">OrderID</TableCell>
                    <TableCell align="left">Cash back</TableCell>
                    <TableCell align="left">Amount (USD)</TableCell>
                    <TableCell align="left">Action</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody >
                  {dispatchedOrders.map(row => (
                    <TableRow key={row.uid}>
                      <TableCell align="left">{new Date(row.created_at).toDateString()}</TableCell>
                      <TableCell align="left">{`${row.recipient.address_1} ${row.recipient.address_2} ${row.recipient.town}`}</TableCell>
                      <TableCell align="left">{row.uid.substring(0, 10).toUpperCase()}</TableCell>
                      <TableCell align="left">{`${row.cashSend ? row.cashSend.amountToBeSent : 0}USD`}</TableCell>
                      <TableCell align="left">{row.totalPriceBreakdown.total}</TableCell>
                      <TableCell align="left"><Button variant="contained" color="secondary" onClick={() => this.pickOrder(row)}>Pick</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          }
        </TabPanel>

        <TabPanel value={value} index={1}>
          {this.nothingFound(orders.filter(x => x.status === 'En_Route' && x.driver_uid === currentUser.uid).length === 0)}
          {
            isMobileOnly ?
              <Grid container spacing={3}>
                {
                  enrouteOrders.map(row => (
                    <Grid item md={12} sm={6} style={{ width: '100%' }} >

                      <Card style={{ marginTop: '1%' }}>
                        <CardContent>
                          <Typography className={classes.typography} gutterBottom variant="h5" component="h2">
                            #{row.uid.toUpperCase().substring(0, 10)}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Created At:</strong> {new Date(row.created_at).toDateString()}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Customer:</strong> {row.customer.fullname}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Cash back 💴:</strong> ${row.cashSend ? row.cashSend.amountToBeSent : 0}USD
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Recipient:</strong> {row.recipient.full_name}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Ship To:</strong> {`${row.recipient.address_1} ${row.recipient.address_2} ${row.recipient.town}`}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            color="secondary"
                            href={`tel:${row.recipient.phone}`}
                            variant={'outlined'}
                            startIcon={<CallIcon />}
                          >
                            Call
                          </Button>
                          <Button
                            size="small"
                            color="secondary"
                            href={
                              `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURI(`${row.recipient.address_1} ${row.recipient.address_2} ${row.recipient.town}`)}`
                            }
                            variant={'outlined'}
                            startIcon={<Nav />}
                          >
                            Navigate
                          </Button>
                          <Button variant="contained" size="small" color="secondary" onClick={() => this.handleDeliveryConfirmOpen(row)}>Deliver</Button>
                          <Button variant={'contained'} size="small" color="primary" onClick={() => this.handleViewOrder(row)}>View</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid> :
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {
                      /**
                       * <TableCell>Created At</TableCell>
                       */
                    }

                    <TableCell align="left">OrderID</TableCell>
                    <TableCell align="left">Cash back</TableCell>
                    <TableCell align="left">Recipient</TableCell>
                    <TableCell align="left">Recipient Phone</TableCell>
                    <TableCell align="left">Ship To</TableCell>
                    {
                      /**
                       * <TableCell align="left">Amount (USD)</TableCell>
                       */
                    }
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {enrouteOrders.map(row => (
                    <TableRow key={row.uid}>
                      {
                        /**
                         * <TableCell align="left">{new Date(row.created_at).toDateString()}</TableCell>
                         */
                      }
                      <TableCell align="left">{row.uid.substring(0, 10).toUpperCase()}</TableCell>
                      <TableCell align="left">{`${row.cashSend ? row.cashSend.amountToBeSent : 0}USD`}</TableCell>
                      <TableCell align="left">{row.recipient.full_name}</TableCell>
                      <TableCell align="left"><Button variant="contained" color="primary" href={`tel:${row.recipient.phone}`}>{row.recipient.phone}</Button></TableCell>
                      <TableCell align="left">{`${row.recipient.address_1} ${row.recipient.address_2} ${row.recipient.town}`}</TableCell>
                      {
                        /**
                         * <TableCell align="left">{row.totalPriceBreakdown.total}</TableCell>
                         */
                      }

                      <TableCell align="left">
                        <Button variant="text" color="secondary" onClick={() => this.handleViewOrder(row)}>View Order</Button>
                        <Button style={{ marginLeft: '2%' }} variant="contained" color="secondary" onClick={() => this.handleDeliveryConfirmOpen(row)}>Delivered</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          }
        </TabPanel>

        <TabPanel value={value} index={2}>
          {
            this.nothingFound(deliveredOrders.length === 0)
          }

          {
            isMobileOnly ?
              <Grid container spacing={3}>
                {
                  deliveredOrders.map(row => (
                    <Grid item md={12} sm={6} style={{ width: '100%' }} >

                      <Card style={{ marginTop: '1%' }}>
                        <CardContent>
                          <Typography className={classes.typography} gutterBottom variant="h5" component="h2">
                            #{row.uid.toUpperCase().substring(0, 10)}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Created At:</strong> {new Date(row.created_at).toDateString()}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                            <strong>Customer:</strong> {row.customer.fullname}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p">
                                <strong>Cash back 💴:</strong> ${row.cashSend ? row.cashSend.amountToBeSent : 0}USD
                              </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Recipient:</strong> {row.recipient.full_name}
                          </Typography>
                          <Typography className={classes.typography} variant="body2" color="textSecondary" component="p" style={{ marginTop: '2%' }}>
                            <strong>Ship To:</strong> {`${row.recipient.address_1} ${row.recipient.address_2} ${row.recipient.town}`}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button variant={'contained'} size="small" color="primary" onClick={() => this.handleViewOrder(row)}>View Order</Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                }
              </Grid> :
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Customer</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell align="left">OrderID</TableCell>
                    <TableCell align="left">Cash back</TableCell>

                    <TableCell align="left">Amount (USD)</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {deliveredOrders.map(row => (
                    <TableRow key={row.uid}>
                      <TableCell component="th" scope="row">
                        {row.customer.fullname}
                      </TableCell>
                      <TableCell align="left">{new Date(row.created_at).toDateString()}</TableCell>
                      <TableCell align="left">{row.uid.substring(0, 10).toUpperCase()}</TableCell>
                      <TableCell align="left">{`${row.cashSend ? row.cashSend.amountToBeSent : 0}USD`}</TableCell>
                      <TableCell align="left">{row.totalPriceBreakdown.total}</TableCell>
                      <TableCell align="left"><Button size="small" color="primary" onClick={() => this.handleViewOrder(row)}>View</Button></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          }
        </TabPanel>


        <Dialog fullScreen open={this.state.open} onClose={this.handleClose.bind(this)}>
          <AppBar style={{ position: 'relative' }} >
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose.bind(this)} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ margin: '0 auto' }}>
                {`#${this.state.selectedOrder.uid ? this.state.selectedOrder.uid.substring(0, 10).toUpperCase() : ""} Checklist`}
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container>
            <div style={{ width: '100%' }}>
              <Grid container>
                <br />
                <br />
                <Grid item sm={6} md={6}>
                  <List>
                    {orderItems[0].map((orderItem, index) => {
                      const labelId = `checkbox-list-label-${index}`;
                      return (
                        <ListItem key={index} role={undefined} dense button>
                          <ListItemIcon>
                            <Checkbox
                              disabled={this.state.view}
                              edge="start"
                              tabIndex={-1}
                              inputProps={{ 'aria-labelledby': labelId }}
                              checked={orderItem.packed}
                            />
                          </ListItemIcon>
                          <ListItemAvatar>
                            <img style={{ height: 50 }} src={orderItem.product.image} />
                          </ListItemAvatar>
                          <ListItemText style={{ width: '35%' }} id={labelId} primary={`${orderItem.product.name} ${orderItem.product.quantity}`} />
                          <ListItemText primary={`X ${orderItem.qty}`} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>

                <Grid sm={6} md={6}>
                  <List>
                    {orderItems[1].map((orderItem, index) => {
                      const labelId = `checkbox-list-label-${index}`;
                      return (
                        <ListItem key={index} role={undefined} dense button>
                          <ListItemIcon>
                            <Checkbox
                              disabled={this.state.view}
                              edge="start"
                              tabIndex={-1}
                              inputProps={{ 'aria-labelledby': labelId }}
                              checked={orderItem.packed}
                            />
                          </ListItemIcon>
                          <ListItemAvatar>
                            <img style={{ height: 50 }} src={orderItem.product.image} />
                          </ListItemAvatar>
                          <ListItemText style={{ width: '35%' }} id={labelId} primary={`${orderItem.product.name} ${orderItem.product.quantity}`} />
                          <ListItemText primary={`X ${orderItem.qty}`} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>

              </Grid>
            </div>
          </Grid>
        </Dialog>

        <Dialog open={this.state.openDeliveryConfirm} onClose={this.handleDeliveryConfirmClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Delivery Verification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To confirm delivery, please enter the 10 character verification code that has been sent to the recipient.
            </DialogContentText>
            <TextField
              margin="dense"
              id="code"
              label="Verification Code"
              type="text"
              fullWidth
              onChange={this.handleTextChange}
              value={this.state.code}
              error={this.state.codeError}
              helperText={this.state.codeError ? "Invalid verification code. Please try again." : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeliveryConfirmClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.deliverOrder(this.state.selectedOrder)} variant="contained" color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>


        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.successDelivery}
          autoHideDuration={6000}
          onClose={this.successDeliveryClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.successDeliveryClose}
            severity="success"
          >
            Order successfully delivered
          </MuiAlert>
        </Snackbar>


        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.pickOrderSuccess}
          autoHideDuration={6000}
          onClose={() => this.setState({ pickOrderSuccess: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ pickOrderSuccess: false })}
            severity="success"
          >
            Order successfully picked for delivery
          </MuiAlert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.pickOrderFailure}
          autoHideDuration={6000}
          onClose={() => this.setState({ pickOrderFailure: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ pickOrderFailure: false })}
            severity="error"
          >
            An error occurred, check your network and try again.
          </MuiAlert>
        </Snackbar>

        <Backdrop open={this.state.mainLoader} style={{
          zIndex: 200,
          color: '#fff'
        }}>
          <CircularProgress color="inherit" />
        </Backdrop>

      </React.Fragment>
    );
  }

}

export default withStyles(styles)(DeliveryWorkspace);