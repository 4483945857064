import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, CartesianGrid, Tooltip } from 'recharts';
import Title from './Title';

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

function getDate(timestamp) {
  let month = new Date(timestamp).getMonth()+1;
  if(month < 10) {
    month = `0${month}`
  }
  return `${new Date(timestamp).getFullYear()}-${month}`;
}

function prepareData(customers=[]) {
  const data = {};
  for(let i = 0; i < customers.length; i++) {
    const customer = customers[i];
    const date = getDate(customer.created_at);
    if(!data[date]) {
      data[date] = 1;
    } else {
      data[date] +=1
    }
  }

  const dateKeys = Object.keys(data).sort();

  const dataResults = []

  for(let i = 0; i< dateKeys.length; i++) {
    dataResults.push({
      date: dateKeys[i],
      value: data[dateKeys[i]]
    })
  }

  return dataResults;

}

export default function Chart(props) {
  const theme = useTheme();

  const data = prepareData(props.data)
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
          width={1000} height={300}
        >
          <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              {props.label}
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="value" stroke={theme.palette.primary.main} dot={false} />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Tooltip />
        </LineChart>
    </React.Fragment>
  );
}