import React from 'react';
import People from '@material-ui/icons/People';
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StorefrontIcon from "@material-ui/icons/Storefront";
import WorkIcon from "@material-ui/icons/Work";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NotificationIcon from '@material-ui/icons/Notifications';
import ReviewIcon from '@material-ui/icons/RateReview';



import Dashboard from "./Containers/Dashboard";
import Orders from "./Containers/Orders";
import Players from "./Containers/Transactions";
import Products from "./Containers/Products";
import Transactions from './Containers/Transactions';
import Customers from './Containers/Customers';
import Workspace from './Containers/Workspace';
import DeliveryWorkspace from './Containers/DeliveryWorkspace';
import Users from './Containers/Users';
import Notifications from './Containers/Notifications';
import Reviews from './Containers/Reviews';
import CustomerLeads from './Containers/CustomerLeads';



export const DashboardsRoutes = [
  {
    title: 'Dashboard',
    path: "/admin/",
    exact: true,
    rolesWhitelisted: ['administrator', 'packager', 'supervisor', 'driver'],
    component: () => <Dashboard />,
    icon: <DashboardIcon />,
  },
  {
    title: 'Packaging',
    path: "/admin/workspace",
    exact: true,
    rolesWhitelisted: ['supervisor', 'administrator', 'packager'],
    component: () => <Workspace />,
    icon: <WorkIcon />,
  },
  {
    title: 'Deliveries',
    path: "/admin/deliveries",
    exact: true,
    rolesWhitelisted: ['supervisor', 'administrator', 'driver'],
    component: () => <DeliveryWorkspace />,
    icon: <LocalShippingIcon />,
  },
  {
    title: 'Orders',
    path: "/admin/orders",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <Orders />,
    icon: <ShoppingCartIcon />
  },
  {
    title: 'Transactions',
    path: "/admin/transactions",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <Transactions />,
    icon: <ReceiptIcon />,
  },
  {
    title: 'Products',
    path: "/admin/products",
    exact: true,
    rolesWhitelisted: ['administrator'],
    component: () => <Products />,
    icon: <StorefrontIcon />
  },
  {
    title: 'Customers',
    path: "/admin/customers",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <Customers />,
    icon: <People />,
  },
  {
    title: 'Leads',
    path: "/admin/leads",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <CustomerLeads />,
    icon: <People />,
  },
  {
    title: 'Users',
    path: "/admin/users",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <Users />,
    icon: <AccountBoxIcon />,
  },
  {
    title: 'Notifications',
    path: "/admin/notifications",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <Notifications />,
    icon: <NotificationIcon />,
  },
  {
    title: 'Reviews',
    path: "/admin/reviews",
    rolesWhitelisted: ['administrator'],
    exact: true,
    component: () => <Reviews />,
    icon: <ReviewIcon />,
  },
];