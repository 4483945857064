import React from 'react';
import * as firebase from "firebase/app";
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import { Button } from '@material-ui/core';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: PageviewRoundedIcon,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

export default class Orders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: 'Created', field: 'created_at',},
        { title: 'ID', field: 'uid', sorting: false, grouping: false,},
        // { title: 'Trxn', field: 'transactionRef', grouping: false},
        { title: 'Status', field: 'status'},
        { title: 'Customer', field: 'customer_name' },
        { title: 'Recipient', field: 'recipient_name'},
        { title: 'Recipient Phone', field: 'recipient_phone'},
        //{ title: 'Ship To', field: 'recipient', render: rowData => `${rowData.recipient.address_1} ${rowData.recipient.address_2} ${rowData.recipient.town}`},
        { title: 'Payment Method', field: 'payment', filtering: false, grouping: false },
        { title: 'Amount (USD)', field: 'amount', filtering: false, grouping: false},
      ],
      data: [],
      open: false,
      selectedEvent: {},
      errorMessage: null,
    }
  }

  componentWillMount() {
    firebase.firestore().collection('orders').orderBy('created_at', 'desc').onSnapshot(orderSnapshots => {
      const data = orderSnapshots.docs.map(order => {
        const rowData = order.data();
        return Object.assign({ uid: order.id.substring(0, 10).toUpperCase() }, 
          {
            created_at: new Date(rowData.created_at).toDateString(),
            transaction_id: rowData.transactionRef,
            status: rowData.status.replace('_', ' ').toUpperCase(),
            customer_name: rowData.customer.fullname,
            recipient_name: rowData.recipient.full_name,
            recipient_phone: rowData.recipient.phone,
            payment: rowData.paymentCard ?  `${rowData.paymentCard.paymentBrand} **** ${rowData.paymentCard.last4Digits}` : "PayFast",
            amount: rowData.totalPriceBreakdown.total,
            // transactionRef: <Button variant="contained" color="primary">{rowData.transactionRef.substring(0, 10)}</Button>
          }
          );
      });
      this.setState({ data, loader: false });
    })
  }

  componentWillUnmount() {

  }

  handleClose() {
    this.setState({ open: false, selectedEvent: {} })
  }

  render() {
    const DB = firebase.firestore();

    return (
      <div>
        <MaterialTable
          title="Active Order Management"
          columns={this.state.columns}
          data={this.state.data}
          icons={tableIcons}
          options={{
            exportButton: true,
            filtering: true,
            grouping: true,
            actionsColumnIndex: -1,
            pageSize: 10,
            exportAllData: true,
          }}
        />

        <Dialog fullScreen open={this.state.open} onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>
          <AppBar style={{ position: 'relative' }} >
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose.bind(this)} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ margin: '0 auto' }}>
                Order Number 100000023
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid container spacing={8}>
            
          </Grid>


        </Dialog>
        
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
