import React from 'react';
import * as firebase from "firebase/app";
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: PageviewRoundedIcon,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

export default class Transactions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: 'Created', field: 'created_at', },
        { title: 'ID', field: 'uid', render: rowData => rowData.uid.substring(0, 10).toUpperCase(), sorting: false, grouping: false  },
        { title: 'External Ref', field: 'externalTransactionRef' },
        { title: 'Status', field: 'status',  grouping: true },
        { title: 'Customer', field: 'customerName'},
        { title: 'Country', field: 'country'},
        { title: 'Amount', field: 'amount', grouping: false },
      ],
      data: [],
      open: false,
      selectedEvent: {},
      errorMessage: null,
    }
  }

  componentWillMount() {
    firebase.firestore().collection('transactions').orderBy('created_at', 'desc').onSnapshot(transactionsSnapshots => {
      const data = transactionsSnapshots.docs.map(trxn => {
        const rowData = trxn.data();
        return Object.assign(rowData, 
          {
            uid: trxn.id.substring(0, 10).toUpperCase(),
            created_at: new Date(rowData.created_at).toDateString(),
            externalTransactionRef: rowData.externalTransactionRef,
          }
          );
      });
      this.setState({ data, loader: false });
    })
  }
  componentWillUnmount() {

  }

  isPaymentSuccessful(code) {
    const successCodes = ['000.000.000', '000.100.110', '000.100.111',
     '000.100.112', '000.400.000', '000.400.010', '000.400.020', '000.400.030',
     '000.400.060', '000.400.070', '000.500.000', '000.500.100', '000.600.000'];
  
     return successCodes.includes(code);
  }

  handleClose() {
    this.setState({ open: false, selectedEvent: {} })
  }

  render() {
    const DB = firebase.firestore();

    return (
      <div>
        <MaterialTable
          title="Transactions"
          columns={this.state.columns}
          data={this.state.data}
          icons={tableIcons}
          options={{
            exportButton: true,
            filtering: true,
            grouping: true,
            actionsColumnIndex: -1,
            pageSize: 20,
            exportAllData: true,
          }}
        />
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
