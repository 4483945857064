import React from 'react';
import Joi from '@hapi/joi';
import LoadingOverlay from 'react-loading-overlay';
import * as firebase from "firebase/app";
import MaterialTable from 'material-table';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import SaveIcon from '@material-ui/icons/Save';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { productSchema } from '../schema';


const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: PageviewRoundedIcon,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};


export default class Products extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          title: 'Image',
          field: 'image',
          filtering: false,
          grouping: false,
          sorting: false,
          headerStyle: { textDecoration: 'none' },
          render: rowData => <img src={rowData.image} style={{ height: 60, alignSelf: 'center' }} />
        },
        {
          title: 'Active',
          field: 'active',
          filtering: true,
          grouping: true,
          sorting: true,
          type: 'boolean'
        },
        {
          title: 'Special Tracking',
          field: 'special_tracking_mode',
          filtering: true,
          grouping: true,
          sorting: true,
          type: 'boolean'
        },
        // { title: 'ID', field: 'product_id', grouping: false, },
        { title: 'Name', field: 'name', grouping: false },
        { title: 'Quatity', field: 'quantity', grouping: false, filtering: false },
        //{ title: 'Description', field: 'description', grouping: false }, 
        {
          title: 'Category', field: 'category',
        },
        { title: 'Units', field: 'units', grouping: false, filtering: false },
        { title: 'Sale Price ', field: 'sale_price', grouping: false, filtering: false },
        { title: 'Price', field: 'price', grouping: false, filtering: false },
        { title: 'Buy Price', field: 'buying_price', grouping: false, filtering: false, render: rowData => (rowData.buying_price * 1).toFixed(2) },
        { title: 'Actions', render: (rowData) => <IconButton onClick={() => this.selectProductForEdit(rowData)} aria-label="edit" color="primary"><Edit /></IconButton> }
      ],
      category: "",
      data: [],
      open: false,
      active: true,
      special_tracking_mode: false,
      selectedEvent: {},
      error: {},
      loader: false,
      productCategories: [],
      selectedProductEdit: null,
      productHamperList: [],
    };
  }

  componentWillMount() {
    const DB = firebase.firestore();

    this.unsubscribeProductsRef = DB.collection('products').onSnapshot(productSnapshot => {
      const product = productSnapshot.docs.map(doc => Object.assign({ uid: doc.id }, doc.data()))
      if (product) {
        this.setState({ data: product });
      }
    });

    this.unsubscribeProductCategoryRef = DB.collection('product_categories').onSnapshot(productCategoriesSnapshot => {
      const productCategories = productCategoriesSnapshot.docs.map(doc => Object.assign({ uid: doc.id }, doc.data()))
      if (productCategories) {
        let categoryObj = {};

        productCategories.forEach(category =>
          categoryObj[category.uid] = category.name,
        )
        if (categoryObj) {
          const columns = this.state.columns;

          columns[5] = {
            title: 'Category',
            field: 'category',
            editable: 'onAdd',
            lookup: categoryObj
          };
          this.setState({ columns });
        }
        this.setState({ productCategories });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeProductsRef();
    this.unsubscribeProductCategoryRef();
  }

  handleClose() {
    this.setState({ open: false, selectedProductEdit: null })
    this.setFormState();
  }

  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ url: e.target.result, error: {} })
      }
      reader.readAsDataURL(image);
      console.log(image)
      this.setState(() => ({ image }));
    }
  };

  handleCategorySelection = event => {
    this.setState({ category: event.target.value, error: {} });
  };

  handleTextChange(field, event) {
    const { value } = event.target
    this.setState({ [field]: value, error: {} });
  }

  selectProductForEdit(product) {
    this.setState({ selectedProductEdit: product, open: true })
    this.setFormState(product)
  }


  setFormState(product = {}) {
    this.setState({
      product_id: product.product_id,
      name: product.name,
      description: product.description,
      active: product.active,
      special_tracking_mode: product.special_tracking_mode,
      category: product.category,
      units: parseInt(product.units),
      quantity: product.quantity,
      price: product.price * 1,
      sale_price: product.sale_price * 1,
      url: product.image,
      buying_price: product.buying_price,
      max_units_per_order: product.max_units_per_order,
    })

    if (Object.keys(product).length === 0) {
      this.setState({ image: null });
    }
  }

  createOrUpdateProduct() {
    const payload = {
      product_id: this.state.product_id,
      name: this.state.name,
      created_at: new Date(),
      description: this.state.description,
      active: this.state.active,
      special_tracking_mode: !!this.state.special_tracking_mode,
      category: this.state.category,
      units: parseInt(this.state.units),
      quantity: this.state.quantity,
      price: this.state.price * 1,
      sale_price: this.state.sale_price * 1,
      image: this.state.url,
      buying_price: this.state.buying_price,
      max_units_per_order: this.state.max_units_per_order || 10,
    }
    const result = Joi.validate(payload, productSchema, { stripUnknown: true, abortEarly: false });

    if (result.error) {
      console.log(payload);
      result.error.details.forEach(detail => {
        const { error } = this.state;
        const { message } = detail;
        const key = message.split(" ")[0].split('"')[1]
        error[key] = message;
        this.setState({ error });
      });
      return false;
    }
    this.setState({ loader: true });

    if (this.state.image instanceof File) {
      const metadata = {
        contentType: this.state.image.type,
      };
      const storageRef = firebase.storage().ref();

      const productImageRef = storageRef.child(`products/${new Date().getSeconds()}-${this.state.image.name}`);
      const uploadInstances = productImageRef.put(this.state.image, metadata);

      uploadInstances.on('state_changed', (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, (error) => {
        console.log("Unsuccessful => ", error);
      }, () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadInstances.snapshot.ref.getDownloadURL().then((downloadURL) => {
          payload.image = downloadURL;
          console.log("uploaded url => ", downloadURL);
          if (this.state.selectedProductEdit) {
            firebase.firestore().collection("products").doc(this.state.selectedProductEdit.uid).update(payload).then(() => {
              console.log("Successfully Created Product");
              this.setFormState();
              this.handleClose();
            }).catch(error => {
              console.log("error => ", error);
            }).finally(() => this.setState({ loader: false }))

          } else {
            firebase.firestore().collection("products").add(payload).then(snapshot => {
              console.log("Successfully Updated Product");
              this.setFormState();
              this.handleClose();
            }).catch(error => {
              console.log("error => ", error);
            }).finally(() => this.setState({ loader: false }))
          }
        });
      });
    } else {
      if (this.state.selectedProductEdit) {
        firebase.firestore().collection("products").doc(this.state.selectedProductEdit.uid).update(payload).then(() => {
          console.log("Successfully updated product");
          this.setFormState();
          this.handleClose();
        }).catch(error => {
          console.log("error => ", error);
        }).finally(() => this.setState({ loader: false }))
      } else {
        firebase.firestore().collection("products").add(payload).then(snapshot => {
          console.log("Successfully Created product");
          this.setFormState();
          this.handleClose();
        }).catch(error => {
          console.log("error => ", error);
        }).finally(() => this.setState({ loader: false }))
      }
    }
  }


  render() {
    console.log("SelectedEdit", this.state.selectedProductEdit)
    return (
      <div>
        <center>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '1%' }}
            onClick={() => this.setState({ open: true })}
          >
            Create Product
          </Button>
          {
            /**
             * <Button
            variant="contained"
            color="primary"
            style={{ margin: '1%' }}
            onClick={() => this.setState({ openCreateHamper: true })}
            disabled={this.state.productHamperList.length === 0}
          >
            Create Hamper
          </Button>
             */
          }
        </center>
        <MaterialTable
          title="Product Stock Management"
          columns={this.state.columns}
          data={this.state.data}
          icons={tableIcons}
          options={{
            exportButton: true,
            filtering: true,
            grouping: true,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20, 40, 50, 100, 120],
            exportAllData: true,
            selection: true,
          }}
          onSelectionChange={(rows) => this.setState({ productHamperList: rows })}
        />

        <Dialog fullScreen open={this.state.open} onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>
          <LoadingOverlay
            active={this.state.loader}
            spinner
            text='Please wait ... Creating Product ...'
            styles={{ wrapper: { width: "100%", height: "100%" } }}
          >
            <AppBar style={{ position: 'relative' }} >
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={this.handleClose.bind(this)} aria-label="Close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" style={{ margin: '0 auto' }}>
                  {this.state.selectedProductEdit ? `Edit Product ${this.state.selectedProductEdit ? this.state.selectedProductEdit.name : ''}` : "Create New Product"}
                </Typography>
                <Button color="secondary" onClick={this.createOrUpdateProduct.bind(this)} startIcon={<SaveIcon />}>
                  Save Product
              </Button>
              </Toolbar>
            </AppBar>
            <Grid container style={{ marginTop: '0.5%' }}>
              {
                this.state.error.image &&
                <Grid container justify="center">
                  <p style={{ color: 'red' }}>Please select a picture for the product </p>
                </Grid>
              }
              <Grid container justify="center">
                {
                  this.state.url ?
                    <img
                      src={this.state.url}
                      alt="Uploaded Images"
                      height="200"
                      style={{ border: " 2px solid grey", borderRadius: '5px' }}
                    /> :
                    <img
                      src={require("../assets/img/photo")}
                      alt="Uploaded Images"
                      height="200"
                    />
                }
              </Grid>

              {
                <Grid container justify="center">
                  <LinearProgress variant="determinate" value={0} color="secondary" />
                </Grid>
              }

              <Grid container justify="center">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={this.handleChange}
                />
                <label
                  htmlFor="contained-button-file"
                //style={{ marginTop: '1%' }}
                >
                  <Button variant="contained" component="span">
                    Choose Photo
                </Button>
                </label>
              </Grid>

              <hr />
              <hr />

              <Grid container spacing={8} style={{ marginLeft: '10%', marginRight: '10%' }}>
                <Grid item md={4} sm={6} >
                  <TextField
                    required
                    label="Name"
                    variant="outlined"
                    placeholder="Enter product name"
                    value={this.state.name}
                    error={!!this.state.error.name}
                    helperText={this.state.error.name}
                    onChange={this.handleTextChange.bind(this, 'name')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Product ID / SKU"
                    variant="outlined"
                    placeholder="Enter product ID or SKU"
                    type="number"
                    value={this.state.product_id}
                    error={!!this.state.error.product_id}
                    helperText={this.state.error.product_id}
                    onChange={this.handleTextChange.bind(this, 'product_id')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Price"
                    variant="outlined"
                    placeholder="Enter product price"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                    value={this.state.price}
                    error={!!this.state.error.price}
                    helperText={this.state.error.price}
                    onChange={this.handleTextChange.bind(this, 'price')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Sale Price"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                    placeholder="Enter sale price"
                    value={this.state.sale_price}
                    error={!!this.state.error.sale_price}
                    helperText={this.state.error.sale_price}
                    onChange={this.handleTextChange.bind(this, 'sale_price')}
                    fullWidth
                  />
                </Grid>
                
                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Buying Price"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                    placeholder="Enter buying price"
                    value={this.state.buying_price}
                    error={!!this.state.error.buying_price}
                    helperText={this.state.error.buying_price}
                    onChange={this.handleTextChange.bind(this, 'buying_price')}
                    fullWidth
                  />
                </Grid>

                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Units"
                    variant="outlined"
                    type="number"
                    placeholder="Enter units"
                    value={this.state.units}
                    error={!!this.state.error.units}
                    helperText={this.state.error.units}
                    onChange={this.handleTextChange.bind(this, 'units')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    label="Max Units allowed per order"
                    variant="outlined"
                    type="number"
                    placeholder="Enter units"
                    value={this.state.max_units_per_order}
                    error={!!this.state.error.max_units_per_order}
                    helperText={this.state.error.max_units_per_order}
                    onChange={this.handleTextChange.bind(this, 'max_units_per_order')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Quatity"
                    variant="outlined"
                    type="text"
                    placeholder="Enter quantity per unit"
                    value={this.state.quantity}
                    error={!!this.state.error.quantity}
                    helperText={this.state.error.quantity}
                    onChange={this.handleTextChange.bind(this, 'quantity')}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    select
                    label="Select Category"
                    value={this.state.category}
                    variant="outlined"
                    onChange={this.handleCategorySelection.bind(this)}
                    placeholder="Please select category"
                    error={!!this.state.error.category}
                    helperText={this.state.error.category}
                    fullWidth
                  >
                    {this.state.productCategories.map((option, index) => (
                      <MenuItem key={index} value={option.uid}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.active} onChange={() => this.setState({ active: !this.state.active })} />
                    }
                    label="Product Active"
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControlLabel
                    control={
                      <Switch checked={this.state.special_tracking_mode} onChange={() => this.setState({ special_tracking_mode: !this.state.special_tracking_mode })} />
                    }
                    label="Special Tracking Mode"
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    required
                    label="Description"
                    variant="outlined"
                    placeholder="Enter product description"
                    value={this.state.description}
                    error={!!this.state.error.description}
                    helperText={this.state.error.description}
                    onChange={this.handleTextChange.bind(this, 'description')}
                    fullWidth
                    multiline
                    rows="2"
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    label="Notes"
                    variant="outlined"
                    placeholder="Enter product notes"
                    value={this.state.notes}
                    error={!!this.state.error.notes}
                    helperText={this.state.error.notes}
                    onChange={this.handleTextChange.bind(this, 'notes')}
                    fullWidth
                    multiline
                    rows="2"
                  />
                </Grid>
              </Grid>
            </Grid>
          </LoadingOverlay>
        </Dialog>

      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
