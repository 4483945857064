import React from 'react';
import * as firebase from "firebase/app";
import MaterialTable from 'material-table';
import Slide from '@material-ui/core/Slide';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { firebaseConfig } from '../App';

const tableIcons = {
  Add: AddBox,
  Check: Check,
  Clear: Clear,
  Delete: DeleteOutline,
  DetailPanel: ChevronRight,
  Edit: Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage: FirstPage,
  LastPage: LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  ResetSearch: Clear,
  Search: Search,
  SortArrow: ArrowUpward,
  ThirdStateCheck: Remove,
  ViewColumn: ViewColumn
};

const roles = ["administrator", "supervisor", "packager", "driver"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default class Users extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { title: 'Registered', field: 'created_at', render: rowData => new Date(rowData.created_at).toDateString() },
        { title: 'Full Name', field: 'fullname' },
        { title: 'Email', field: 'email' },
        { title: 'Phone', field: 'phone' },
        {
          title: 'Role', field: 'customer_name', filter: false, sort: false, render: rowData => {
            return (
              <FormControl>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  value={rowData.roles || []}
                  onChange={(event) => this.handleChangeMultiple(event, rowData.uid)}
                  input={<Input />}
                  MenuProps={MenuProps}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}>
                      {selected.map((value) => (
                        <Chip color="primary" key={value} label={value} style={{ margin: 2 }} />
                      ))}
                    </div>
                  )}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role} style={this.getStyles(role, rowData.roles || [])}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          }
        },
        { title: 'Country', field: 'country', render: rowData => rowData.country ? rowData.country.name : '' },
      ],
      data: [],
      open: false,
      selectedEvent: {},
      errorMessage: null,
      error: {},
    }
  }


  componentWillMount() {
    firebase.firestore().collection('profile').where('admin', '==', true).onSnapshot(profileSnapshots => {
      const data = profileSnapshots.docs.map(profile => {
        return Object.assign({ uid: profile.id }, profile.data());
      });
      this.setState({ data, loader: false });
    })
  }

  componentWillUnmount() {

  }

   calculateCartPrice(cart) {
    let subTotal = 0;
 
    for (let i = 0; i < cart.length; i++) {
      const { product, qty } = cart[i];
      subTotal += product.sale_price * qty;
    }
    return subTotal.toFixed(2);
  }

  getStyles(name, userRoles) {
    return {
      fontWeight:
        userRoles.indexOf(name) === -1
          ? 'normal'
          : 'bold',
    };
  }

  handleChangeMultiple = (event, uid) => {
    console.log("event =>", event);
    console.log("customer uid =>", uid)
    const options = event.target.value;
    /**
     * const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
     */

    firebase.firestore().collection('profile').doc(uid).update({ roles: options, admin: true });
  };

  handleClose() {
    this.setState({ open: false, selectedEvent: {} })
  }

  handleTextChange(field, event) {
    const { value } = event.target
    this.setState({ [field]: value, error: {} });
  }

  handleOnCloseRegDialog() {
    this.setState({ name: '', email: '', phone: '', password: '', registration: false });
  }

  registerUser() {
    const { email, password, phone, name } = this.state;

    if(!name) {
      this.setState({ error: { name: true } })
      return;
    }

    if(!email) {
      this.setState({ error: { email: true } })
      return;
    }

    if(!phone) {
      this.setState({ error: { phone: true } })
      return;
    }

    if(!password) {
      this.setState({ error: { password: true } })
      return;
    }
    
    
    const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

    this.setState({ loader: true });
    secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(response => {
      secondaryApp.auth().currentUser.updateProfile({
        displayName: name,
      });
      secondaryApp.auth().sendPasswordResetEmail(email);

      const userId = response.user.uid;

      firebase.firestore().collection('profile').doc(userId).set({ email, fullname: name, phone, admin: true, created_at: new Date().getTime() });

      this.setState({ loader: false })
      this.handleOnCloseRegDialog()
    }).catch((error) => {
      this.setState({ loader: false })
      this.setState({ regError: error.message || "Unexpected error occurred" })
    })
  }

  render() {
    const DB = firebase.firestore();
    return (
      <div style={{ margin: '2%' }}>
        <center>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '1%' }}
            onClick={() => this.setState({ registration: true })}
          >
            Registered User
          </Button>
        </center>
        <MaterialTable
          title="User Management"
          columns={this.state.columns}
          data={this.state.data}
          icons={tableIcons}
          options={{
            exportButton: true,
            filtering: true,
            grouping: true,
            actionsColumnIndex: -1,
            pageSize: 10,
            exportAllData: true,
          }}
        />


        <Dialog open={this.state.registration} onClose={this.handleOnCloseRegDialog.bind(this)} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">User Registration</DialogTitle>
          <DialogContentText style={{ color: 'red' }}>
            {this.state.regError}
          </DialogContentText>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Full name"
              type="text"
              fullWidth
              onChange={this.handleTextChange.bind(this, 'name')}
              error={this.state.error.name}
              helperText={this.state.error.name? "Invalid full name" : ''}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={this.handleTextChange.bind(this, 'email')}
              error={this.state.error.email}
              helperText={this.state.error.email ? "Invalid email address" : ""}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Phone"
              type="text"
              fullWidth
              onChange={this.handleTextChange.bind(this, 'phone')}
              error={this.state.error.phone}
              helperText={this.state.error.phone ? "Invalid phone number" : ""}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Password"
              type="password"
              fullWidth
              onChange={this.handleTextChange.bind(this, 'password')}
              error={this.state.error.password}
              helperText={this.state.error.password? "Invalid password" : ''}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleOnCloseRegDialog.bind(this)} color="primary">
              Cancel
          </Button>
            <Button color="primary" onClick={this.registerUser.bind(this)}>
              Register
          </Button>
          </DialogActions>
        </Dialog>

        <Backdrop open={this.state.loader} style={{
          zIndex: 20000,
          color: '#fff'
        }}>
          <CircularProgress color="inherit" />
        </Backdrop>

      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
