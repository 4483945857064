import React from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import FirestoreSchema from "firestore-schema";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import './App.css';
import Login from './Containers/Login';
import Admin from './Containers/Admin';


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_API_KEY : process.env.REACT_APP_DEVELOPMENT_API_KEY,
  authDomain: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_AUTH_DOMAIN : process.env.REACT_APP_DEVELOPMENT_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_DATABASE_URL : process.env.REACT_APP_DEVELOPMENT_DATABASE_URL,
  projectId: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_PROJECT_ID : process.env.REACT_APP_DEVELOPMENT_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_STORAGE_BUCKET: process.env.REACT_APP_DEVELOPMENT_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_MESSAGING_SENDER_ID : process.env.REACT_APP_DEVELOPMENT_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_APP_ID : process.env.REACT_APP_DEVELOPMENT_APP_ID,
  measurementId: process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PRODUCTION_MEASUREMENT_ID : process.env.REACT_APP_DEVELOPMENT_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseAppInstance = firebase.initializeApp(firebaseConfig);

FirestoreSchema.initialize(firebaseAppInstance); 

firebase.firestore().enablePersistence();

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

function App() {

  const [authStatus, setAuthStatus] = React.useState(false);
  React.useEffect(()=> {
    firebase.auth().onAuthStateChanged((user) =>{
      if (user) {
        setAuthStatus(true);
      } else {
        // No user is signed in.
        setAuthStatus(false);
      }
    });
  }, [])
  return (
    <Router>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      {
        authStatus &&
        <Route path="/admin" component={Admin} />
      }
    </Router>
  );
}

export default App;

