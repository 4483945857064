import React from 'react';
import * as firebase from "firebase/app";
import 'firebase/firebase-functions';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const supportedTarget = ["All", "Android", "iOS"]
export default class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      os: '',
      title: '',
      message: '',
      titleError: false,
      messageError: false,
      osError: false,
      review: false,
      feedBackSuccess: false,
      feedBackError: false,
      loader: false,
      campaigns: [],
    }
  }

  componentWillMount() {
    firebase.firestore().collection("campaign").orderBy('created_at', 'desc').onSnapshot(campaignSnapshots => {
      const campaigns = campaignSnapshots.docs.map(x => Object.assign({ uid: x.id}, x.data()));
      this.setState({ campaigns });
    });
  }

  handleTextChange(field, event) {
    const { value } = event.target
    this.setState({ [field]: value, titleError: false, messageError: false, osError: false });
  }

  handleChange = (event) => {
    this.setState({ os: event.target.value, osError: false });
    console.log(event.target.value);
  };

  review() {
    this.setState({ review: true });
  }

  closeReview() {
    this.setState({ review: false });
  }

  submitPushNotification() {
    const { title, os, message } = this.state;
    if (!supportedTarget.includes(os)) {
      this.setState({ osError: true, review: false })
      return;
    }

    if (!title) {
      this.setState({ titleError: true, review: false })
      return;
    }

    if (!message) {
      this.setState({ messageError: true, review: false })
      return;
    }

    const payload = {
      os,
      title,
      message
    }
    this.setState({ loader: true, review: false });

    const broadCastPushNotification = firebase.functions().httpsCallable('broadCastPushNotification');
    broadCastPushNotification(payload).then(results => {
      this.setState({ message: '', title: '', os: '', review: false, loader: false, feedBackSuccess: true });
    }).catch(error => {
      this.setState({ feedBackError: true, review: true });
    })

  }

  render() {
    return (
      <React.Fragment>
        <Paper style={{ margin: '2%', height: 450, }}>
          <br />
          <h2 style={{ alignContent: 'center', marginLeft: '10%' }}>Broadcast Push Notification</h2>

          <div style={{ marginLeft: '10%', width: '40%' }}>
            <TextField
              select
              fullWidth
              label="Select Target OS"
              variant="outlined"
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              error={this.state.osError}
              value={this.state.os}
              onChange={this.handleChange}
            >
              <MenuItem value="">
                <em>Please Select</em>
              </MenuItem>
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Android"}>Android</MenuItem>
              <MenuItem value={"iOS"}>iOS</MenuItem>
            </TextField>

            <br />

            <TextField
              fullWidth style={{ marginTop: '5%' }}
              id="outlined-basic" label="Notification Title"
              variant="outlined"
              value={this.state.title}
              error={this.state.titleError}
              onChange={this.handleTextChange.bind(this, 'title')}
              inputProps={{
                maxlength: 50
              }}
            />

            <TextField
              fullWidth
              style={{ marginTop: '5%' }}
              id="outlined-basic"
              label="Notification Message"
              variant="outlined"
              error={this.state.messageError}
              value={this.state.message}
              onChange={this.handleTextChange.bind(this, 'message')}
              multiline rows="3"
              inputProps={{
                maxlength: 150
              }}
            />

            <Button fullWidth onClick={this.review.bind(this)} style={{ marginTop: '5%' }} variant="contained" color="primary">
              Review Notification
            </Button>

          </div>
        </Paper>

        <Paper style={{ margin: '2%'}}>
        <Table >
                <TableHead>
                  <TableRow>
                    <TableCell>Created At</TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Target OS</TableCell>
                    <TableCell align="left">Audience</TableCell>
                    <TableCell align="left">Report Processed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {this.state.campaigns.map(row => (
                    <TableRow key={row.uid}>
                      <TableCell align="left">{new Date(row.created_at).toString().substring(0, 21)}</TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left">{row.message}</TableCell>
                      <TableCell align="left">{row.targetOs === "All" ? "Android & iOS" :  row.targetOs }</TableCell>
                      <TableCell align="left">{row.audience}</TableCell>
                      <TableCell align="left">{row.processed ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </Paper>

        <Dialog
          open={this.state.review}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.closeReview.bind(this)}
        >
          <DialogTitle id="alert-dialog-slide-title">{"Review the broadcast you are about to send"}</DialogTitle>
          <DialogContent>
            <h3>Target OS</h3>
            {this.state.os}
            <h3>Title</h3>
            {this.state.title}
            <h3>Message</h3>
            {this.state.message}
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeReview.bind(this)} color="primary">
              Cancel
          </Button>
            <Button onClick={this.submitPushNotification.bind(this)} color="primary">
              Send Broadcast
          </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.feedBackSuccess}
          autoHideDuration={6000}
          onClose={() => this.setState({ feedBackSuccess: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ feedBackSuccess: false })}
            severity="success"
          >
            Push Notification Broadcast Successfully Sent!
          </MuiAlert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.feedBackError}
          autoHideDuration={6000}
          onClose={() => this.setState({ feedBackError: false })}>
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ feedBackError: false })}
            severity="error"
          >
            An error occurred, check your network and try again.
          </MuiAlert>
        </Snackbar>

        <Backdrop open={this.state.loader} style={{
          zIndex: 200000,
          color: '#fff'
        }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
}