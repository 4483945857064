import Joi from '@hapi/joi'

export const productSchema = Joi.object().keys({
    name: Joi.string().trim().required(),
    product_id: Joi.number().required(),
    price: Joi.number().min(0.001).required(),
    sale_price: Joi.number().min(0.001).required(),
    buying_price: Joi.number().min(0.001).required(),
    units: Joi.number().min(0).required(),
    max_units_per_order: Joi.number().min(0).optional(),
    quantity: Joi.string().required(),
    created_at: Joi.date().required(),
    category: Joi.string().required(),
    active: Joi.boolean().required(),
    description: Joi.string().trim().required(),
    noted: Joi.string().optional(),
    image: Joi.string().required(),
});
